<div class="container">
  <div class="heading">
    <p>Unsubscribe Newsletter</p>
  </div>
  <div class="formBody">
    <form [formGroup]="unsubscribeForm" (ngSubmit)="formSubmit($event, errorLabel)">
        <p class="error-lbl" #errorLabel>{{errorMsg}}</p>
        <div class="first-row">
          <div id="lbl" class="cols-xs-3">{{lbl1}}</div>
          <div id="field">
            <input class="txtFieldCls" formControlName="email">
          </div>
        </div>
        <div class="second-row">
          <div id="lbl" class="cols-xs-3">{{lbl2}}</div>
          <div id="field">
            <textarea class="txtFieldCls textarea" formControlName="reason"></textarea>
          </div>
        </div>
        <div class="btnPnl">
          <button type="submit" [ngClass]="{'disable': !unsubscribeForm.valid}" >Unsubscribe</button>
        </div>
    </form>
  </div>
</div>