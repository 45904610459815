<div *ngIf="prodData != undefined" class="tileWrapper" [class.showHoverDiv]="showVariants" [class.on-product-tile]="onProductTile">
  <div class="imageWrapper" style="position:relative">
    <a [routerLink]="'/'+target" [queryParams]="queryParams">
      <img [src]="(prodData?.imageUrl && prodData?.images && prodData?.images[0])?prodData.imageUrl+'m/'+prodData.images[0]:defaultPath "
        class="img-responsive" alt="" (error)="imgErrorHandler($event)" *ngIf="(!showCarousel) && (!showVarImg)">
      <img [src]="(varImageUrl)?varImageUrl:defaultPath" class="img-responsive" alt="" (error)="imgErrorHandler($event)" *ngIf="(!showCarousel) && (showVarImg)">
    </a>
  </div>
  <div class="detailDiv">
    <!-- <p id="productName"> {{productName}}</p> -->
    <a [routerLink]="'/'+target" [queryParams]="queryParams" id="productName">{{productName}}</a>
    <div class="ratingDiv" *ngIf="ratingValue > 0">
      <ls-rating [editable]=false name="ratingVal" [(ngModel)]="ratingValue" ></ls-rating>
    </div>
    <div class="priceDiv">
      <div id="salePriceDiv">
        <p id="productSalePrice">{{prodData.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
      </div>
      <div id="discountDiv" [ngClass]="{'marginBottom': onProductTile}">
        <p id="productMrp" *ngIf="prodData.mrp > prodData.salePrice && showSaving == 1">
          {{prodData.mrp | currency:currType:'symbol': priceRoundOffInteger}}
        </p>
        <!-- <p id="discountLbl" *ngIf="onProductTile">{{prodData.discountPercent | number: '1.1'}}%&nbsp; OFF</p> -->
      </div>
    </div>
    <div class="buyNowPnl">
      <div class="addWishPanel" (click)="addToWishlist()" *ngIf="allowWishlist">
        <span class="fa fa-heart-o"></span>
      </div>
    </div>
    <!-- <div class="buyNowPnl hide" (click)="addToCartProduct()" *ngIf="(prodData?.outOfStock == false && allowCart || (prodData?.outOfStock == true && allowCart && prodData?.acceptOrderOnOutOfStock == 1)) && (!prodData?.releaseDate || (prodData?.releaseDate && prodData?.releaseDate < todaysDate))">
      <a>Buy Now</a>
    </div>
    <div class="buyNowPnl hide" (click)="addPreOrderProduct(prodData?.titleId)" *ngIf="(prodData?.releaseDate && prodData?.releaseDate > todaysDate)">
      <a>Pre-Order</a>
    </div> -->
  </div>
  <div class="hoverDiv">
    <div class="pnlFilterItrOuter">
      <ul class="filterItr">
        <div class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
          <div class="pnlMandtryFieldInner">
            <p class="variantDesc">{{variantType.variantHeading}}</p>
            <ul class="variantItr">
              <li class="variantItrLi" *ngFor="let variant of variantType.options; let optionIdx = index;"> <!-- (click)="setProductVariant(variant.mappedUniqueIds, optionIdx)" -->
                <p class="filter">{{variant.option}}</p>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </div>
  </div>
</div>