<div fxLayout="column" class="login-component" [ngClass]="{'small-width': !fbOauth && !googleOauth}">
  <div>
    <div class="login heading-lbl">
      <p class="heading">Login to {{warName}}</p>
      <span class="close-pop-up" (click)="onClick()"></span>
    </div>
    <div class="leftPnl col-xs-6" [ngClass]="{'col-xs-12': !fbOauth && !googleOauth}">
      <div class="tab">
        <div class="login-tab" (click)="changeTab()" [ngClass]="{'active': isLoginTab}" #login>
          <p>Login</p>
        </div>
        <div class="signup-tab" (click)="changeTab()" [ngClass]="{'active': !isLoginTab}" #signUp>
          <p>Signup</p>
        </div>
      </div>
      <p class="error-lbl" #errorLabel></p>
      <div class="login-form" #loginTab [ngClass]="{'hide': !isLoginTab}">
        <form role="form" [formGroup]="loginForm" (ngSubmit)="loginSubmit($event, errorLabel)" class="login-desk-view">
          <div class="login-email">
            <input formControlName="email" type="email" tabindex="1" id="txtEmailLogin" class="txtFieldCls txtEmailLogin"
              placeholder="Enter email address">
          </div>
          <div class="login-password">
            <input formControlName="password" type="password" tabindex="2" id="txtPasswordLogin" class="txtFieldCls txtPasswordLogin"
              placeholder="Password">
          </div>
          <div class="button-div">
            <button id="lnkLoginBtn" class="button lnkLoginBtn" tabindex="4">Login</button>
          </div>
          <div>
            <div class="chckbx">
              <input formControlName="remember" class="chckbx-selector" type="checkbox" tabindex="3" id="rememberMeChk">
              <label class="chckbx-label" for="rememberMeChk">Remember Me</label>
            </div>
            <a (click)="goToForgotPw()" tabindex="5" id="lnkForgotPwd" class="lnkForgotPwd">Forgot password?</a>
          </div>
        </form>
      </div>
      <div class="signup-form hide" #signUpForm [ngClass]="{'hide': isLoginTab}">
        <form id="frmSignup" [formGroup]="registerForm" (ngSubmit)="signUpSubmit($event, errorLabel)" class="sign-up-view">

          <div class="signup-name">
            <input type="text" formControlName="name" placeholder="Name" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup">
          </div>
          <div class="signup-email">
            <input type="email" formControlName="emailSignUp" placeholder="Email" tabindex="1" id="txtEmailSignup"
              class="txtFieldCls txtEmailSignup">
          </div>
          <div class="signup-password">
            <input type="password" tabindex="3" formControlName="passwordSignUp" placeholder="Password" id="txtPasswordSignup"
              class="txtFieldCls txtPasswordSignup">
            <div id="pnlStrength" class="pnlStrength">
              <span id="spPwdStrength"></span>
            </div>
          </div>
          <div class="button-div">
            <button id="lnkSignupBtn" class="button lnkSignupBtn" tabindex="4"> Signup</button>
          </div>
        </form>
      </div>
    </div>
    <div class="rightPnl loginVia col-xs-6" [ngClass]="{'hide': !fbOauth && !googleOauth}">
      <div class="social-signin-container">
        <div *ngIf="fbOauth" id="fbPnl" class="fbPnl" tabindex="10" (click)="socialSignIn('facebook', errorLabel)">
          <img src="assets/images/facebook_login.png">
          <a id="fbLnk" class="lnkFB">Connect with Facebook </a>
        </div>
        <div id="gPlusPnl" *ngIf="googleOauth" class="gPlusPnl" tabindex="11" (click)="socialSignIn('google', errorLabel)">
          <img src="assets/images/google_login.png">
          <a id="gPlusLnk" class="gPlusLnk">Connect with Google</a>
        </div>
      </div>
      <img src="assets/images/or.png" class="or-img">
    </div>
  </div>
</div>