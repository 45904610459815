<div *ngFor="let collection of collectionArray; let i=index;" [ngClass]="{'hide': (isDetailPage && collection?.detailPageVisibility == 0) || (isDetailPage && collection?.collectionType == CONSTANTS?.DEFAULT_COLLECTION_TYPE?.recentlyViewed) || collection?.productModels?.length <= 0 || (collection?.collectionType == CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts && collection?.productModels && collection?.productModels.length == 1 && collection?.productModels[0].titleId == newProd?.titleId)}">
  <div class="catHeadingWrapper" [ngClass]="{'boxShadow': isDetailPage}">
    <p class="catHeading">{{collection?.collectionTitleName}}</p>
    <div class="gridArrow" [ngClass]="{'disable': leftValue[i] <= maxLeftValue[i]  || collection?.productModels?.length <= showCount}"
      (click)="moveLeft(container, i)">
      <span class="fa fa-chevron-right"></span>
    </div>
    <div class="gridArrow" [ngClass]="{'disable': leftValue[i] >= 0  || collection?.productModels?.length <= showCount}"
      (click)="moveRight(container, i)">
      <span class="fa fa-chevron-left"></span>
    </div>
  </div>
  <div class="mainDiv">
    <div class="productsWrapper">
        <!-- && productData?.titleId == newProd?.titleId //todo -->
      <a routerLink="/collections/{{collection?.collectionURL}}" class="gridViewT" *ngIf="collection?.collectionType != CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts ">View</a>
      <div [style.left.px]="leftValue[i]" class="productList">
        <div class="products" *ngFor="let productData of collection?.productModels;"  [ngClass]="{'hide': collection?.collectionType == CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts && productData?.titleId == newProd?.titleId, 'disabled': productData?.outOfStock == true}" #container>
          <app-product-tile [tile-data]="productData" style="width: 100%" (addToCartWishlist)="addToWishlist($event)"
            (addToCart)="addToCartProduct($event)"></app-product-tile>
        </div>
      </div>
    </div>
  </div>
</div>