<div class="survey-component" fxLayout="column">
    <div class="heading" fxLayout="row">
        <div class="logo">
            <a [routerLink]="''" style="height:100%; width:100%; position:relative;">
                <img [src]="companyDetails.storeLogo" style="padding-left: 20px;" (error)="imgErrorHandler($event)"
                    title="{{companyDetails.storeName}}" alt="{{companyDetails.storeName}}">
            </a>
        </div>
        <div class="title" fxLayoutAlign="center center">
            <p class="form-heading">Customer Survey</p>
        </div>
        <div class="close-icon" fxLayoutAlign="center center">
            <div class="fa fa-close" (click)="close(false)"></div>
        </div>
    </div>
    <div class="offer-text">
        <p class="line1">Avail 15% OFF</p>
        <p class="line2">Coupons on completion of this survey</p>
    </div>
    <div class="main-form">
        <form [formGroup]="surveyForm" (ngSubmit)="surveySubmit($event)">
            <div class="questions">
                <div class="questionText" *ngIf="this.surveyQuestion0">
                    <p>{{this.surveyQuestion0.questionId}}. {{this.surveyQuestion0.questionName}}</p>
                    <div class="answer">
                        <div class="radio" *ngFor="let option of surveyQuestion0OptionMap">
                            <input type="radio" name="q1" class="radio-button" [value]="option.optionId"
                                formControlName="q1">
                            <label>{{option.option}}</label>
                        </div>
                    </div>
                </div>
                <div class="questionText" *ngIf="this.surveyQuestion1">
                    <p>{{this.surveyQuestion1.questionId}}. {{this.surveyQuestion1.questionName}}</p>
                    <div class="answer">
                        <div class="radio" *ngFor="let option of surveyQuestion1OptionMap">
                            <input type="radio" class="radio-button" name="q2" [value]="option.optionId"
                                formControlName="q2">
                            <label>{{option.option}}</label>
                        </div>
                        <input class="textbox" type="text" formControlName="q2Other" maxlength="100">
                    </div>
                </div>
                <div class="questionText" *ngIf="this.surveyQuestion2">
                    <p>{{this.surveyQuestion2.questionId}} .{{this.surveyQuestion2.questionName}}</p>
                    <div class="rating-bar">
                        <ls-rating class="ratingDivs" [editable]=true name="ratingVal" formControlName="ratingValue"></ls-rating>
                    </div>
                </div>
                <div class="questionText" *ngIf="this.surveyQuestion3">
                    <p>{{this.surveyQuestion3.questionId}}. {{this.surveyQuestion3.questionName}}</p>
                    <div class="text-area-element">
                        <textarea class="text-area" formControlName="q3"></textarea>
                    </div>
                </div>
                <div class="submitBtn" fxLayoutAlign="center center" (click)="surveySubmit($event)">
                    <p class="buttonText">SUBMIT</p>
                </div>
            </div>
        </form>
    </div>
</div>