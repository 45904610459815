<div class="forgot-pass-word">
  <div class="heading">
      <p> Forgot Password?</p>
  </div>
  <form id="forgot-pwd" [formGroup]="forgotPwdForm" (ngSubmit)="forgotPwdRequest($event)">
    <div id="forgotPwdCntnt" class="forgotPwdCntnt">
      <div id="pnlForgotPwdText" class="pwdPnl">
        <p id="lblForgotPwdText" class="lblPwd">
          To reset your password, enter the Username or Email Id you use to login:
        </p>
      </div>
      <div id="pnlEmailForgotPwd" class="pnlEmailForgotPwd">
        <div id="pnlInnerEmailForgotPwd" class="pwdPnl">
          <p id="lblEmailForgotPwd" class="lblPwd">
            Enter Email*
          </p>
        </div>
        <input type="text" formControlName="email" tabindex="1" id="txtEmailForgotPwd" class="txtFieldCls txtFldDashboard">
      </div>
      <div id="forgotPwdBtnPnl" class="forgotPwdBtnPnl">
        <div id="pnlSubmitBtn" class="pnlCancelBtn"  [ngClass]="{'disable': !forgotPwdForm.valid}">
          <button id="lnkSubmitBtn" class="button lnkCancelBtn" type="submit" tabindex="2">
            Submit
          </button>
        </div>
        <div id="pnlCancelBtn" class="pnlCancelBtn">
          <button id="lnkCancelBtnForgot" class="button lnkCancelBtn" type="submit" tabindex="3" (click)="navigateToHome()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>