<div id="pnlNoteBody" class="pnlNoteBody">
  <div class="heading hide">
    <p>Order Cancelled</p>
  </div>
  <div id="pnlNoteContent" class="pnlNoteContent">
    <div id="pnlNoteContentInner" class="pnlNoteContentInner">
      <div id="pnlThankNote" class="pnlNote">
        <p id="lblThankNote" class="lblThankNote">
          Thank you for using {{storeName}}. However, the transaction has been aborted.
        </p>
      </div>
    </div>
  </div>
</div> 