import { Component, OnInit , Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HomePageService } from '../../../../../services/home-page.service';
import { BannerModel } from '../../../../../common/models/bannerModel';
import { Router } from '@angular/router';
import { TemplateConstants } from '../../../../commonComponent/template-constants';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  constructor(public homePageService: HomePageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) { }

  offers: Array<BannerModel> = new Array();
  defaultPath = TemplateConstants.templateAssetsPath+"/images/offer/offer1.jpg";
  demoOffer: BannerModel = new BannerModel();
  isMobile: boolean = false

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(window.navigator.userAgent)) {
        this.isMobile = true
      } else if (/Mobi|Tablet|iPad|kindle/i.test(window.navigator.userAgent)) {
        this.isMobile = false
      } else {
        this.isMobile = false
      }
    }
    this.homePageService.getBannerOffer(false, (data) => {
      if (!data.error && data.data) {
        this.offers = data.data;
      } else {
        this.demoOffer.imageName = TemplateConstants.templateAssetsPath+"/images/offer/offer1.jpg";
        this.demoOffer.altText = 'defaultOffer1';
        this.demoOffer.videoURL = "";
        this.offers.push(this.demoOffer);
        this.demoOffer = new BannerModel();
        this.demoOffer.imageName = TemplateConstants.templateAssetsPath+"/images/offer/offer2.jpg";
        this.demoOffer.altText = 'defaultOffer2';
        this.demoOffer.videoURL = "";
        this.offers.push(this.demoOffer);
        this.demoOffer = new BannerModel();
        this.demoOffer.imageName = TemplateConstants.templateAssetsPath+"/images/offer/offer3.jpg";
        this.demoOffer.altText = 'defaultOffer3';
        this.demoOffer.videoURL = "";
        this.offers.push(this.demoOffer);
        this.demoOffer = new BannerModel();
        this.demoOffer.imageName = TemplateConstants.templateAssetsPath+"/images/offer/offer4.jpg";
        this.demoOffer.altText = 'defaultOffer4';
        this.demoOffer.videoURL = "";
        this.offers.push(this.demoOffer);
      }
    });
  }

  navigate(href) {
    if (href != undefined && href != '') {
      if (href.indexOf('collections') > -1) {
        this.router.navigate(['collections' + href.split('collections')[1]]);
      } else {
        window.location.href = href;
      }
    }
  }

  offerImgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
